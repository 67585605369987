import React, { useEffect, useState, Fragment } from 'react';
import { Grid, TextField, Button, Typography, Box } from '@mui/material';
import axios from 'axios';
import { toast } from "react-toastify";
import CustomLoader from '../../components/CustomLoader';
import { isValidUrl } from '../../utils';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import Quill stylesheet

const EventForm = (props) => {
    const [event, setEvent] = useState({
        name: '',
        image: null,
        link: '',
        shortDescription: '',
        longDescription: '',
        eventDate: '',
        // eventTime: '',
        venue: '',
    });
    const [loading, setLoading] = useState(false);
    const [imagePreview, setImagePreview] = useState('');


    const handleChange = (field, value) => {
        setEvent((prevData) => ({ ...prevData, [field]: value }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImagePreview(URL.createObjectURL(file));
            setEvent({ ...event, image: file });
        } else {
            setImagePreview('');
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        const formData = new FormData();
        if (!event.image) {
            toast.error('Image is required.');
            setLoading(false);
            return;
        }
        if (!isValidUrl(event.link)) {
            toast.error('Please enter a valid URL.');
            setLoading(false);
            return;
        }
        if (!event.longDescription) {
            toast.error('Long Description is required');
            setLoading(false);
            return;
        }
        if (props.eventId) {
            formData.append('id', props.eventId);
        }
        formData.append('image', event.image);
        formData.append('name', event.name);
        formData.append('link', event.link);
        formData.append('shortDescription', event.shortDescription);
        formData.append('longDescription', event.longDescription);
        formData.append('eventDate', new Date(event.eventDate));
        // formData.append('eventTime', date.toTimeString().split(' ')[0]);
        formData.append('venue', event.venue);
        axios.post(process.env.REACT_APP_API_BASE_URL + '/events/addUpdate', formData)
            .then(response => {
                toast.success('Successfully created the event');
                props.setAddEvent(false);
                setLoading(false);
            })
            .catch(error => {
                toast.error('Error creating event, Please try again');
                setLoading(false);
            });
    };
    useEffect(() => {
        if (props?.eventId) {
            axios.get(process.env.REACT_APP_API_BASE_URL + '/events/' + props.eventId).then((response) => {
                if (response?.data) {
                    const date = new Date(response.data.eventDate);
                    setEvent({
                        name: response.data.name,
                        link: response.data.link,
                        shortDescription: response.data.shortDescription,
                        longDescription: response.data.longDescription,
                        eventDate: date.toISOString().slice(0, 16), // Date and time (YYYY-MM-DDTHH:MM)
                        venue: response.data.venue,
                        image: response.data.image,
                    });
                }
            });
        }
    }, [props?.eventId]);

    return (
        <Fragment>
            <CustomLoader show={loading} />
            <section className="min-height-500">
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Event Name"
                                name="name"
                                variant="outlined"
                                value={event.name}
                                onChange={(e) => handleChange('name', e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                component="label"
                                fullWidth
                            >
                                Upload Image
                                <input
                                    type="file"
                                    hidden
                                    name="image"
                                    onChange={handleFileChange}
                                // required
                                />
                            </Button>
                        </Grid>
                        {imagePreview && (
                            <Box
                                component="img"
                                src={imagePreview}
                                alt="Preview"
                                sx={{
                                    mt: 2,
                                    ml: 2,
                                    maxWidth: '100%',
                                    height: 'auto',
                                    borderRadius: 1,
                                }}
                            />
                        )}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Link"
                                name="link"
                                variant="outlined"
                                value={event.link}
                                onChange={(e) => handleChange('link', e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                label="Short Description"
                                name="shortDescription"
                                variant="outlined"
                                value={event.shortDescription}
                                onChange={(e) => handleChange('shortDescription', e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mb: 1 }}>
                                Long Description
                            </Typography>
                            <ReactQuill
                                value={event.longDescription}
                                onChange={(value) => handleChange('longDescription', value)}
                                modules={{
                                    toolbar: [
                                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                                        [{ 'size': [] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                                        ['link', 'image'],
                                        [{ 'align': [] }],
                                        ['clean']
                                    ],
                                }}
                                theme="snow"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Event Date"
                                name="eventDate"
                                type="datetime-local"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                value={event.eventDate}
                                // inputProps={{
                                //     min: new Date().toISOString().slice(0, 16),
                                // }}
                                onChange={(e) => handleChange('eventDate', e.target.value)}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Venue"
                                name="venue"
                                variant="outlined"
                                value={event.venue}
                                onChange={(e) => handleChange('venue', e.target.value)}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                            >
                                Create Event
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </section >
        </Fragment>
    );
};

export default EventForm;
