import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Grid, Button, Box, Tooltip } from '@mui/material';
import { formatDate } from '../../utils';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';

const Events = (props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            console.log("Screen size changed:", window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <div className="wpo-section-wrapper">
            <div className="row text-align-justify pt-20 pb-10">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="wpo-about-content">
                        <div className="about-title">
                            <h2>{props.title}</h2>
                        </div>
                        <Box padding={isMobile ? 1 : 4}>
                            <Grid container spacing={4}>
                                {props.eventList.length > 0 ? props.eventList.map((event) => (
                                    <Grid item xs={12} sm={6} md={4} key={event._id}>
                                        <Card style={{
                                            "position": "relative",
                                            // "width": "300px",
                                            "border-radius": "10px",
                                            "overflow": "hidden"
                                        }}>
                                            <div className="date-container" style={{
                                                "position": "absolute",
                                                "top": "5px",
                                                "left": "5px",
                                                "background": "white",
                                                "color": "black",
                                                "padding": "8px",
                                                "border-radius": "5px",
                                                "text-align": "center",
                                                "min-width": "55px"
                                            }}>
                                                <Typography variant="h5" className="date">{new Date(event.eventDate)?.getDate()}</Typography>
                                                <Typography variant="body2" className="month">{new Date(event.eventDate)?.toLocaleString("en-US", { month: "short" })}</Typography>
                                            </div>
                                            <div className="date-container" style={{
                                                "position": "absolute",
                                                "top": "0px",
                                                "right": "0px",
                                                "background": "white",
                                                "color": "black",
                                                "padding": "8px",
                                                "text-align": "center",
                                                "min-width": "50px"
                                            }}>
                                                <Typography variant="body2" className="year">{new Date(event.eventDate)?.getFullYear()}</Typography>
                                            </div>
                                            <CardMedia component="img" height="200"
                                                image={process.env.REACT_APP_API_BASE_URL + '/events/image/' + event.image.split("=")[event.image.split("=").length - 1]}
                                                alt={event.name} />
                                            <CardContent>
                                                <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems={{ xs: 'left', sm: "center" }} marginBottom={1}>
                                                    <Typography variant="h5" className="event-title">{event.name}</Typography>
                                                    {/* <Box display="flex" alignItems="center">
                                                        <EventIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                        <Typography variant="subtitle1" className="event-date">
                                                            {formatDate(event.eventDate)}
                                                        </Typography>
                                                    </Box> */}
                                                </Box>
                                                <Box display="flex" alignItems="center">
                                                    <EventIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                    <Typography variant="subtitle1" className="event-date">
                                                        {formatDate(event.eventDate)}
                                                    </Typography>
                                                </Box>
                                                <br />
                                                <Tooltip title={event.shortDescription} arrow>
                                                    <Typography variant="body2" color="textSecondary" sx={{
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        display: "-webkit-box",
                                                        WebkitLineClamp: 2,
                                                        WebkitBoxOrient: "vertical",
                                                        lineHeight: "1.5em",
                                                        height: "3em",
                                                        cursor: "pointer"
                                                    }}>
                                                        {event.shortDescription}
                                                    </Typography>
                                                </Tooltip>

                                                <Box display="flex" alignItems="center" marginTop={2}>
                                                    <LocationOnIcon fontSize="small" sx={{ mr: 0.5 }} />
                                                    <Typography variant="subtitle1">
                                                        Venue: {event.venue}
                                                    </Typography>
                                                </Box>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size='small'
                                                    fullWidth
                                                    sx={{ mt: 3 }}
                                                    onClick={() => props.handleEventClick(event)}>
                                                    View Details
                                                </Button>
                                                {props.title === 'Past Events' ?
                                                    <Button
                                                        // variant="contained"
                                                        color="secondary"
                                                        size='small'
                                                        fullWidth
                                                        sx={{ mt: 1 }}
                                                        onClick={() => props.goToGallery()}>
                                                        View Event Highlights
                                                    </Button> : null}
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )) : null}
                                {props.noText !== "" ? <h3 className='pd-20'>{props.noText}</h3> : null}
                            </Grid>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Events;
