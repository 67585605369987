import img1 from '../../images/Members/1.jpg';
import img2 from '../../images/Members/2.jpg';
import img3 from '../../images/Members/3.jpg';
import img4 from '../../images/Members/4.jpg';
import img5 from '../../images/Members/5.jpg';
import img6 from '../../images/Members/6.jpg';
import img7 from '../../images/Members/7.jpg';
import img8 from '../../images/Members/8.jpg';
import img9 from '../../images/Members/9.jpg';

export const memberData = [
    {
        "id": 1,
        "name": "Rishi Shah",
        "role": "President",
        "img": img1
    },
    {
        "id": 2,
        "name": "Jayendra Monpara",
        "role": "Vice - President",
        "img": img2
    },
    {
        "id": 3,
        "name": "Piyush Hirpara",
        "role": "Secretary",
        "img": img3
    },
    {
        "id": 4,
        "name": "Nikunj Mehta",
        "role": "Joint Secretary",
        "img": img4
    },
    {
        "id": 5,
        "name": "Dr. Joravarsinh Rana (PhD, MRSC)",
        "role": "Treasurer",
        "img": img5
    },
    {
        "id": 6,
        "name": "Kulin Varma",
        "role": "Committee Member",
        "img": img6
    },
    {
        "id": 7,
        "name": "Amit Patel",
        "role": "Committee Member",
        "img": img7
    },
    {
        "id": 8,
        "name": "Anand Jain",
        "role": "Committee Member",
        "img": img8
    },
    {
        "id": 9,
        "name": "Nikunjbhai Sakhreliya",
        "role": "Committee Member",
        "img": img9
    }
]