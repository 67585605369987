import React, { Fragment, useState, Suspense, lazy, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Footer from '../../components/footer';
import Hero from '../../components/hero';
import Navbar from '../../components/Navbar';
import Scrollbar from '../../components/scrollbar';
import Logo from '../../images/logo-big.png';
import CustomLoader from '../../components/CustomLoader';
import CustomPopup from '../../components/CustomPopup';
const Highlights = lazy(() => import('../Highlights'));
const EventCarousel = lazy(() => import('../Events/EventCarousel'));

const HomePage = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleClose = () => {
        setShowPopup(false);
    }

    useEffect(() => {
        window.scroll(0, 0);
        setTimeout(() => {
            setLoading(false);
        }, 200);
        if (!localStorage.getItem('welcome')) {
            setShowPopup(true);
            localStorage.setItem('welcome', true)
        }
    }, []);

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home</title>
                <link rel="canonical" href="https://gujaratisamaj.ie/" />
                <meta name='description' content='Gujarati Samaj Ireland' />
                <meta property="og:title" content="Home" />
                <meta property="og:description" content="Gujarati Samaj Ireland" />
                <meta name="twitter:title" content="Home" />
                <meta name="twitter:description" content="Gujarati Samaj Ireland" />
            </Helmet>
            <CustomLoader show={loading} />
            <Navbar topbarBlock={'wpo-header-style-3'} hclass={'wpo-header-style-3'} Logo={Logo} />
            <Hero />
            <CustomPopup open={showPopup} msg="ગુજરાતી સમાજ આયર્લેન્ડ મા આપનું સ્વાગત છે" close={handleClose} showHand={true} addClass='f-s-20 font-bold' />
            <section className="wpo-section section-padding">
                <div className="wpo-section-wrapper">
                    <div className="row text-align-justify pt-10 pb-10">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="wpo-about-content">
                                <div className="wpo-about-content-inner">
                                    <p className='f-s-18 text-align-center'><i>Welcome to the official website of Gujarati Samaj Ireland (GSI)!</i></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-align-justify pt-10 pb-10">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="wpo-about-content">
                                <div className="wpo-about-content-inner">
                                    <p className='f-s-18'>Our main goal is to honour and cherish our diverse Gujarati culture and heritage.
                                        We achieve this by hosting a variety of cultural, educational, and recreational events that involve our members and add vibrancy to Ireland's multicultural landscape.</p>
                                    <p className='f-s-18'>We're proud to organize numerous cultural activities such as Holi, Janmashtami, Navratri, Diwali, and more.
                                        These events showcase our dedication for preserving Gujarati culture in Ireland.</p>
                                    <p className='f-s-18'>Join us as we continue to cherish and celebrate our rich heritage here in Ireland!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Suspense fallback={<div></div>}>
                        <EventCarousel />
                    </Suspense>
                </div>
            </section>
            <Suspense fallback={<div></div>}>
                <Highlights />
            </Suspense>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default HomePage;