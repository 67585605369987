import React, { useEffect, useState, Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo-big.png'
import { Helmet } from "react-helmet";
import CustomLoader from '../../components/CustomLoader';
import Lightbox from 'react-image-lightbox';
import './style.scss';
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import 'react-image-lightbox/style.css';
import { Button } from "@mui/material";

const PhotosGallery = () => {
    const [folders, setFolders] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [showFolder, setShowFolder] = useState(false);
    const [loader, setLoader] = useState(false);
    const [noText, setNoText] = useState("");
    const [selectedFolder, setSelectedFolder] = useState("");
    const [selectedFolderId, setSelectedFolderId] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchFolders = async () => {
        try {
            setNoText("")
            setShowFolder(true);
            setPhotos([]);
            setSelectedFolderId("");
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/photo');
            const data = await response.json();
            setFolders(data);
            if (data.length <= 0) {
                setNoText("No Images")
            }
            setLoader(false);
        } catch (error) {
            console.error('An error occurred during fetch:', error);
            setNoText("Please, Try again. Sorry for the trouble.")
            setLoader(false);
        }
    }

    // Fetch images for the album with pagination
    const fetchImages = (albumId, pageToken = null) => {
        setIsLoading(true);
        setNoText("")
        setLoader(true);
        setShowFolder(false);
        const url = new URL(process.env.REACT_APP_API_BASE_URL + '/photo/' + albumId + '/photos');
        const params = { pageToken, limit: 25 };
        Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                const { mediaItems, nextPageToken } = data;

                // Append new images to the existing photos
                setPhotos((prevPhotos) => [...prevPhotos, ...mediaItems]);

                // Update the next page token
                setNextPageToken(nextPageToken);
                setIsLoading(false);
                if (data.length <= 0) {
                    setNoText("No Images")
                }
                setLoader(false);
            })
            .catch((error) => {
                console.error("Error fetching images:", error);
                setIsLoading(false);
                setLoader(false);
            });
    };

    // Handle Load More button click
    const handleLoadMore = (albumId) => {
        fetchImages(albumId, nextPageToken);
    };

    const handleFolderClick = (folderId, folderName) => {
        window.scrollTo(0, 0)
        setSelectedFolder(folderName);
        setPhotos([]);
        setSelectedFolderId(folderId);
        fetchImages(folderId);
    };

    const handleBackClick = () => {
        fetchFolders();
    };

    const renderMedia = (media) => {
        if (media.mimeType.startsWith("image/")) {
            return <img src={`${media.baseUrl}=w800-h600`} alt={media.filename} style={{ width: '100%' }} />;
        } else if (media.mimeType.startsWith("video/")) {
            return (
                <video controls style={{ width: "100%" }} autoPlay>
                    <source src={media.baseUrl} type={media.mimeType} />
                    Your browser does not support the video tag.
                </video>
            );
        }
        return null;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        setLoader(true);
        fetchFolders();
        setSelectedFolderId("");
        setPhotos([]);
    }, []);


    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Gallery</title>
                <link rel="canonical" href="/gallery" />
                <meta name='description' content='' />
                <meta property="og:title" content="Gallery" />
                <meta property="og:description" content="" />
                <meta name="twitter:title" content="Gallery" />
                <meta name="twitter:description" content="" />
            </Helmet>
            <CustomLoader show={loader} />
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle='Gallery' pagesub='Gallery' />
            <section className="custom-section-padding">
                <div className="wpo-section-wrapper min-height-500">
                    <div className="about-title display-flex align-item-center">
                        <h2>Moments We Cherish</h2>
                        <CameraAltIcon className="icon f-s-36 pl-5" />
                    </div>
                    <div className="row text-align-justify pt-20 pb-10 gallery-container">
                        {!showFolder ? <div onClick={handleBackClick} className='backButton pb-10'>
                            <i className="fa fa-arrow-left icon"></i>
                            <span className='backText'>Back</span>
                        </div> : null}
                        {!showFolder ? <h2>{selectedFolder}</h2> : null}
                        {noText !== "" ? <h3 className='pd-20'>{noText}</h3> : null}
                        {showFolder ? folders.length > 0 && folders?.reverse().map((folder) => (
                            <div
                                onClick={() => handleFolderClick(folder.id, folder.title)}
                                className='folderItem'>
                                <div className='iconContainer'>
                                    <img
                                        src={folder.coverPhotoBaseUrl || "https://via.placeholder.com/200"}
                                    />
                                </div>
                                <div className='folderName'>
                                    {folder.title}
                                </div>
                            </div>
                        ))
                            : photos.length > 0 && (
                                <>
                                    <div style={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                                        gap: "10px",
                                        justifyContent: "center"
                                    }}>
                                        {photos.map((item, index) => (
                                            item.mimeType.startsWith("image/") ?
                                                <div key={item.id}
                                                    onClick={() => {
                                                        setIsOpen(true);
                                                        setPhotoIndex(index);
                                                    }}>
                                                    <img
                                                        src={item.baseUrl}
                                                        alt="Google Photo"
                                                        style={{ width: "100%", height: "auto", aspectRatio: "1 / 1", objectFit: "cover", borderRadius: "10px" }}
                                                    />
                                                </div> : null
                                        ))}
                                    </div>
                                    {nextPageToken && (
                                        <Button
                                            className='mt-10'
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleLoadMore(selectedFolderId)}
                                            disabled={isLoading}
                                        >
                                            {isLoading ? "Loading..." : "Load More"}
                                        </Button>
                                    )}
                                </>
                            )
                        }
                        {isOpen && (
                            <Lightbox
                                mainSrc={photos[photoIndex]?.baseUrl}
                                nextSrc={photos[(photoIndex + 1) % photos.length]?.baseUrl}
                                prevSrc={photos[(photoIndex + photos.length - 1) % photos.length]?.baseUrl}
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() =>
                                    setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
                                }
                                onMoveNextRequest={() =>
                                    setPhotoIndex((photoIndex + 1) % photos.length)
                                }
                            />
                        )}
                    </div>
                </div>
            </section >
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default PhotosGallery;
