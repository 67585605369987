import React, { useEffect, Fragment, useState } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo-big.png'
import { Helmet } from "react-helmet";
import CustomLoader from '../../components/CustomLoader';
import { Card, CardContent, CardMedia, Typography, Box, Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { formatDate } from '../../utils';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventIcon from '@mui/icons-material/Event';
import { useNavigate } from 'react-router-dom';

const EventDetailsPage = () => {
    const location = useLocation();
    const event = location.state?.event;
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const goToGallery = () => {
        navigate(`/gallery/`);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
            console.log("Screen size changed:", window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    if (!event) return <Typography variant="h5">Event not found</Typography>;

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Events</title>
                <link rel="canonical" href="/events" />
                <meta name='description' content='' />
                <meta property="og:title" content="Events" />
                <meta property="og:description" content="" />
                <meta name="twitter:title" content="Events" />
                <meta name="twitter:description" content="" />
            </Helmet>
            <CustomLoader show={false} />
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            {/* <PageTitle pageHeaderImage={'wpo-events-area'} pagesub={'Events'} /> */}
            <PageTitle pageTitle={'Events'} pagesub={'Events'} />
            <section className="event-details-padding min-height-500">
                <Box padding={4}>
                    <Card>
                        <CardMedia
                            component="img"
                            height="300"
                            image={process.env.REACT_APP_API_BASE_URL + '/events/image/' + event.image.split("=")[event.image.split("=").length - 1]}
                            alt={event.name}
                            style={{ objectFit: "contain" }} />
                        <CardContent>
                            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                                <Typography variant="h4">{event.name}</Typography>
                                {!isMobile ? <Box display="flex" alignItems="center">
                                    <EventIcon fontSize="medium" sx={{ mr: 0.5 }} />
                                    <Typography variant="h6">
                                        {formatDate(event.eventDate)}
                                    </Typography>
                                </Box> : null}
                            </Box>
                            {isMobile ? <Box display="flex" alignItems="center">
                                <EventIcon fontSize="medium" sx={{ mr: 0.5 }} />
                                <Typography variant="h6">
                                    {formatDate(event.eventDate)}
                                </Typography>
                            </Box> : null}
                            <br />
                            <div dangerouslySetInnerHTML={{ __html: event.longDescription }} />
                            <Box display="flex" alignItems="center" marginTop={2}>
                                <LocationOnIcon fontSize="medium" sx={{ mr: 0.5 }} />
                                <Typography variant="h6">
                                    Venue: {event.venue}
                                </Typography>
                            </Box>
                            {new Date(event.eventDate) > new Date() ? event.link && (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    href={event.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    sx={{ mt: 3 }}>
                                    Book your tickets here
                                </Button>
                            ) : <Button
                                variant="contained"
                                color="primary"
                                size='small'
                                fullWidth
                                sx={{ mt: 1 }}
                                onClick={() => goToGallery()}>
                                View Event Highlights
                            </Button>}
                        </CardContent>
                    </Card>
                </Box>
            </section >
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default EventDetailsPage;
