import * as React from 'react';
import { Helmet } from "react-helmet";

export default function TermsAndCondition() {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Terms & Condition</title>
                <link rel="canonical" href="https://gujaratisamaj.ie/faq" />
                <meta property="og:title" content="Terms & Condition" />
                <meta name="twitter:title" content="Terms & Condition" />
            </Helmet>
            <div className='pt-50 pb-50 pl-100 pr-100'>
                <h1>Terms and Conditions for Inquiry Submissions</h1>

                <p><strong>1. Acceptance of Terms</strong></p>
                <p>By submitting an inquiry through our website, you agree to these Terms and Conditions.
                    If you do not agree, please refrain from using this service.</p>

                <p><strong>2. Purpose of Inquiry Submission</strong></p>
                <p>
                    The inquiry form is intended for users to contact us regarding questions, support, or other general inquiries.
                    It is not to be used for spam, promotions, or unsolicited offers.
                </p>

                <p><strong>3. Accuracy of Information</strong></p>
                <p>You agree that any information you submit is accurate, complete, and truthful. We are not responsible for any
                    consequences resulting from incorrect or misleading information provided by users.</p>

                <p><strong>4. Privacy and Data Usage</strong></p>
                <p>
                    Your submitted information will be handled in accordance with our Privacy Policy. We do not sell, rent, or share your personal
                    data with third parties without your consent, except as required by law.
                </p>

                <p><strong>5. No Guarantee of Response</strong></p>
                <p>
                    While we strive to respond to all inquiries promptly, we do not guarantee a response or specific resolution timeframe.
                </p>

                <p><strong>6. Limitation of Liability</strong></p>
                <p>
                    We are not responsible for any loss, damage, or harm arising from your use of the inquiry service. This includes but is not limited to,
                    delays in response or any inaccuracies in the information provided.
                </p>

                <p><strong>7. Changes to Terms</strong></p>
                <p>
                    We reserve the right to update these Terms and Conditions at any time without prior notice. Continued use of the
                    inquiry form signifies acceptance of any modifications.
                </p>

                <p><strong>8. Contact Information</strong></p>
                <p>
                    If you have any questions about these Terms and Conditions, please contact us through
                    the designated communication channels provided on our website.
                </p>
            </div>
        </>
    );
}