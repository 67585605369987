import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';
import { userQuery } from '../../store/actions/contactActions';
import CustomLoader from '../CustomLoader';
import { toast } from "react-toastify";


const ContactForm = () => {
    const dispatch = useDispatch();
    const push = useNavigate();

    const [loading, setLoading] = useState(false);

    const [forms, setForms] = useState({
        name: '',
        surname: '',
        email: '',
        // subject: '',
        // phone: '',
        message: '',
        remember: false
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const newLink = () => {
        window.open('/termsAndCondition', '_blank');
    };

    const submitHandler = e => {
        e.preventDefault();
        setLoading(true);
        if (forms.remember) {
            if (validator.allValid()) {
                validator.hideMessages();
                dispatch(userQuery(forms, push));
                setForms({
                    name: '',
                    email: '',
                    surname: '',
                    remember: false,
                    // subject: '',
                    // phone: '',
                    message: ''
                })
                setLoading(false);
            } else {
                validator.showMessages();
                setLoading(false);
                setForms({ ...forms });
            }
        } else {
            validator.showMessages();
            setLoading(false);
            toast.error("Please accept our terms and conditions");
        }
    };

    return (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
            <CustomLoader show={loading} />
            <div className="row">
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Name" />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.surname}
                            type="text"
                            name="surname"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Surname" />
                        {validator.message('surname', forms.surname, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Email" />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                {/* <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.phone}
                            type="number"
                            name="phone"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your phone" />
                        {validator.message('phone', forms.phone, 'required|phone')}
                    </div>
                </div> */}
                <div className="col col-lg-12 col-12">
                    <textarea
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.message}
                        type="text"
                        name="message"
                        placeholder="Message">
                    </textarea>
                    {validator.message('message', forms.message, 'required')}
                </div>
                <div className="col col-lg-12 col-12">
                    <label className='display-flex justify-center align-items-center justify-flex-start'>
                        <input
                            className='width-20'
                            value={forms.remember}
                            type="checkbox"
                            name="remember"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Terms & Condition" />
                        <span className='pl-10 cursor-pointer' onClick={newLink}>By clicking this, you agree to our <u className='color-blue'>Terms & Conditions</u></span>
                    </label>
                    {validator.message('remember', forms.remember, 'required')}
                </div>
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn">Submit Now</button>
            </div>
        </form >
    )
}

export default ContactForm;