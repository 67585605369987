import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo-big.png'
import { Helmet } from "react-helmet";
import CommitteeSection from "../Committee";
import { Card, CardContent, Typography, CardMedia, Grid } from '@mui/material';
import img1 from '../../images/community.jpg';
import img2 from '../../images/garba2.jpg'
import img3 from '../../images/unity.jpg';

const AboutPage = () => {
    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>About Us</title>
                <link rel="canonical" href="/about" />
                <meta name='description' content='' />
                <meta property="og:title" content="Our Story" />
                <meta property="og:description" content="" />
                <meta name="twitter:title" content="Our Story" />
                <meta name="twitter:description" content="" />
            </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            {/* If we want the title 
            pageTitle={'About Us'}
            */}
            <PageTitle pagesub={'About Us'} pageTitle='About Us' />
            <section className="wpo-section custom-section-padding">
                <div className="wpo-section-wrapper">
                    <div className="row text-align-justify pt-20 pb-10">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="wpo-about-content">
                                <div className="about-title">
                                    <h2>What is GSI</h2>
                                </div>
                                <div className="wpo-about-content-inner">
                                    <p className='f-s-18'>Gujarati Samaj Ireland (GSI) is a community comprising individuals and families of
                                        Indian origin with connections to Gujarat and the rich heritage of Gujarati culture.
                                        Our dedicated members volunteer their efforts towards preserving and promoting the essence of
                                        Gujarati heritage, cultural values, and the Gujarati language.
                                        Our primary aim is to ensure that these invaluable aspects are upheld for the
                                        benefit of future generations within the Irish community.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-align-justify pt-20 pb-10 background-light-grey">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="wpo-about-content">
                                <div className="about-title">
                                    <h2>Our History</h2>
                                </div>
                                <div className="wpo-about-content-inner">
                                    <p className='f-s-18'>
                                        Gujarati Samaj Ireland (GSI) Established in 2013, and received its official registration on October 10, 2023.
                                        During the festive Diwali Sneh Milan celebration on November 18, 2023, the inaugural committee members of GSI were formally introduced.
                                    </p>
                                    <p className='f-s-18'>
                                        Our journey began with the commemoration of Shri Krishna Janmashtami in 2013, marking GSI's first community event.
                                        Since then, we have joyously celebrated a variety of events annually, including the GSI Picnic,
                                        Holi, Navratri, Diwali, and more. These occasions bring together our vibrant community, fostering
                                        cultural connections and creating memorable experiences for all.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-align-justify pt-20 pb-10 background-light-grey">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="wpo-about-content">
                                <div className="about-title">
                                    <h2>Our Vision</h2>
                                </div>
                                <div className="wpo-about-content-inner">
                                    <p className='f-s-18'>Our vision is to preserve and uphold the rich cultural and linguistic legacy of the Gujarati heritage through embracing diversity,
                                        fostering a strong sense of belonging for the generations to come.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row text-align-justify pt-20 pb-10">
                        <div className="wpo-about-content">
                            <div className="about-title">
                                <h2>Our Mission</h2>
                            </div>
                            <div className="wpo-about-content-inner">
                                <p className='f-s-18'>Our mission is to nurture strong community ties and unity while preserving and celebrating
                                    Gujarati heritage through a diverse range of cultural, educational, and recreational activities in Ireland.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row text-align-justify pt-20 pb-10 background-light-grey">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="wpo-about-content">
                                <div className="about-title">
                                    <h2>Our Values</h2>
                                </div>
                                <div className="wpo-about-content-inner">
                                    <Grid container spacing={3} justifyContent="center">
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Card sx={{ minWidth: 200, margin: 2, boxShadow: 3, textAlign: 'center', justifyContent: 'center', padding: '20px' }}>
                                                <CardMedia
                                                    component="img"
                                                    height="150"
                                                    image={img1}
                                                    alt="Card image"
                                                    maxWidth="80%"
                                                    sx={{ objectFit: 'contain' }}
                                                />
                                                <CardContent>
                                                    <Typography variant="h5" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                                        Community
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Card sx={{ minWidth: 200, margin: 2, boxShadow: 3, textAlign: 'center', justifyContent: 'center', padding: '20px' }}>
                                                <CardMedia
                                                    component="img"
                                                    height="150"
                                                    image={img3}
                                                    alt="Card image"
                                                    maxWidth="80%"
                                                    sx={{ objectFit: 'contain' }}
                                                />
                                                <CardContent>
                                                    <Typography variant="h5" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                                        Unity
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Card sx={{ minWidth: 200, margin: 2, boxShadow: 3, textAlign: 'center', justifyContent: 'center', padding: '20px' }}>
                                                <CardMedia
                                                    component="img"
                                                    height="150"
                                                    image={img2}
                                                    alt="Card image"
                                                    maxWidth="80%"
                                                    sx={{ objectFit: 'contain' }}
                                                />
                                                <CardContent>
                                                    <Typography variant="h5" color="text.secondary" sx={{ fontWeight: 'bold' }}>
                                                        Cultural Enrichment
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <section className="wpo-section custom-section-padding pt-0">
                <CommitteeSection />
            </section>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};

export default AboutPage;
