import React, { useEffect, useState, Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle';
import Navbar from '../../components/Navbar';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo-big.png'
import { Helmet } from "react-helmet";
import CustomLoader from '../../components/CustomLoader';
import { useNavigate } from 'react-router-dom';
import Events from './Events';

const EventPage = () => {
    const [upcoming, setUpcoming] = useState([]);
    const [past, setPast] = useState([]);
    const [loading, setLoading] = useState(false);
    const [noUpcoming, setNoUpcoming] = useState("");
    const [noPast, setNoPast] = useState("");

    const navigate = useNavigate();

    const handleEventClick = (event) => {
        navigate(`/events/${event._id}`, { state: { event } });
    };

    const goToGallery = () => {
        navigate(`/gallery/`);
    };

    const fetchEvents = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/events');
            const data = await response.json();
            const currentDate = new Date().setHours(0, 0, 0, 0);
            const upcoming = data.filter(event => new Date(event.eventDate).getTime() >= currentDate);
            setUpcoming(upcoming);
            const past = data.filter(event => new Date(event.eventDate).getTime() < currentDate);
            setPast(past);
            if (upcoming.length <= 0) {
                setNoUpcoming("No Events");
            }
            if (past.length <= 0) {
                setNoPast("No Events")
            }
            setLoading(false);
        } catch (error) {
            console.error('An error occurred during fetch:', error);
            setNoUpcoming("Please, Try again after sometime. Sorry for the trouble.");
            setLoading(false);
        }
    }

    useEffect(() => {
        setLoading(true);
        fetchEvents();
    }, []);

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Events</title>
                <link rel="canonical" href="/events" />
                <meta name='description' content='' />
                <meta property="og:title" content="Events" />
                <meta property="og:description" content="" />
                <meta name="twitter:title" content="Events" />
                <meta name="twitter:description" content="" />
            </Helmet>
            <CustomLoader show={loading} />
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <PageTitle pageTitle='Events' pagesub='Events' />
            <section className="custom-section-padding">
                <Events eventList={upcoming} handleEventClick={handleEventClick} noText={noUpcoming} title='Upcoming Events' />
            </section >
            <section className="section-padding">
                <Events eventList={past} handleEventClick={handleEventClick} noText={noPast} title='Past Events' goToGallery={goToGallery} />
            </section >
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};

export default EventPage;
