import React from 'react';
import './style.scss';
import Loader from '../../images/loader.gif';

const CustomLoader = ({ show }) => {
    if (!show) {
        return null; // If show is false, don't render anything
    }
    return (
        <div className="loader">
            <img src={Loader} alt="" />
        </div>
    );
};

export default CustomLoader;
